import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { LoadingLogo } from './components/general/LoadingLogo'
import Layout from './components/layout/dashboard/Layout'
import { UserContext } from './contexts/UserContext'
import NoAccess from './NoAccess'

export const Base = () => {
  const { token, fetchCurrentUser } = useContext(UserContext)
  const { data: userData, status: userStatus } = useQuery('current-user', fetchCurrentUser)
  const location = useLocation()

  if (!token && location.pathname !== '/login') {
    return <Navigate to='login' />
  }

  return (
    <Wrapper>
      {userData &&
        userStatus === 'success' &&
        (userData.role === 'administrator' ? (
          <Layout>
            <Outlet />
          </Layout>
        ) : (
          <>
            <NoAccess />
          </>
        ))}
      {(userStatus === 'loading' || !userData) && (
        <div className='loading-page'>
          <LoadingLogo />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .loading-page {
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`
