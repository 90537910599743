import React, { lazy } from 'react'
import './index.css'
import 'antd/dist/antd.css'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { Base } from './Base'
import { Login } from './components/layout/Login'
import { Logout } from './components/layout/Logout'
import { theme } from './GlobalStyles'
import NoAccess from './NoAccess'
import { SuperBase } from './SuperBase'

const Brand = lazy(() => import('./components/layout/dashboard/brand/Brand'))
const Brands = lazy(() => import('./components/layout/dashboard/brand/Brands'))
const Campaign = lazy(() => import('./components/layout/dashboard/campaign/Campaign'))
const CampaignsList = lazy(() => import('./components/layout/dashboard/campaign/CampaignsList'))
const Home = lazy(() => import('./components/layout/dashboard/Home'))
const OptIn = lazy(() => import('./components/layout/dashboard/opt-in/OptIn'))
const OptInsList = lazy(() => import('./components/layout/dashboard/opt-in/OptInsList'))
const UserPage = lazy(() => import('./components/layout/dashboard/user/UserPage'))
const UsersList = lazy(() => import('./components/layout/dashboard/user/UsersList'))
const Utility = lazy(() => import('./components/layout/dashboard/Utility'))

export const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <SuperBase />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'logout', element: <Logout /> },
        { path: 'no-access', element: <NoAccess /> },
        { path: '*', element: <NoAccess /> },
        {
          path: '',
          element: <Base />,
          children: [
            { path: '', element: <CampaignsList /> },
            { path: 'campaigns', element: <CampaignsList /> },
            { path: 'campaign/:id', element: <Campaign /> },
            { path: 'opt-ins', element: <OptInsList /> },
            { path: 'opt-in/:id', element: <OptIn /> },
            { path: 'brand/:id', element: <Brand /> },
            { path: 'brands', element: <Brands /> },
            { path: 'users', element: <UsersList /> },
            { path: 'user/:id', element: <UserPage /> },
            { path: 'payments', element: <Home /> },
            { path: 'utility', element: <Utility /> },
            { path: 'logout', element: <Logout /> },
          ],
        },
      ],
    },
  ])

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <ReactQueryDevtools defaultIsOpen={false} position='bottom-right' />
    </ThemeProvider>
  )
}
